@import '../../../scss/helpers/positions';

@keyframes scale__0_7__1 {
  0% {
    transform: scale(0.7);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes scale__0_7__1_1 {
  0% {
    transform: scale(0.7);
  }

  100% {
    transform: scale(1.1);
  }
}

@keyframes menu_entry_fade_in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.8;
  }
}

.HomeImageBlock {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  right: 0;
  height: 100%;
  padding: 0px;
  overflow: hidden;

  &__wrapper--16_9__image,
  &__wrapper--16_9__video {
    border: 0;
    box-sizing: border-box;
    height: 56.25vw;
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    width: 177.77777778vh;

    > img,
    video {
      display: block;
      @include pos-absolute(0, 0, 0, 0);
      width: 100%;
      height: 100%;
    }
  }

  &__menu {
    display: block;
    @include pos-absolute(0, 0, 0, 0);
    width: 100%;
    height: 100%;

    &__entry {
      position: absolute;
      display: block;
    }

    .menu_entry {
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      cursor: pointer;
      opacity: 0.8;
      transition: opacity var(--ac-transition-normal) ease-in-out;

      &--animation-fade-in {
        animation: menu_entry_fade_in 0.2s both ease-in;

        @for $i from 1 through 20 {
          &:nth-child(#{$i}) {
            animation-delay: 0.2s + $i * 0.1s;
          }
        }
      }

      &__dot {
        background: white;
        border-radius: 50%;

        animation: scale__0_7__1 1.5s alternate infinite ease-in;
      }

      &__label {
        position: absolute;
        color: white;

        .label {
          display: block;
          line-height: 1;
        }

        &--anchor {
          &-left {
            right: 0;
            top: 0;
            transform: translateY(-50%);
          }

          &-topleft {
            right: 0;
            bottom: 0;
          }

          &-top {
            left: 0;
            transform: translateX(-50%);
            bottom: 0;
          }

          &-topright {
            left: 0;
            bottom: 0;
          }

          &-right {
            left: 0;
            top: 0;
            transform: translateY(-50%);
          }

          &-bottomright {
            left: 0;
            top: 0;
          }

          &-bottom {
            left: 0;
            transform: translateX(-50%);
            top: 0;
          }

          &-bottomleft {
            right: 0;
            top: 0;
          }
        }
      }

      &:hover {
        opacity: 1 !important;
      }
    }
  }
}
