.report-reasons {
  display: flex;
  flex-direction: column;
  min-height: 200px;
  width: 100%;
  gap: 8px;
  & .ui.dropdown {
    width: 100%;
  }
  &__message {
    width: 100%;
    flex: 1;
  }

  & textarea {
    border: 1px solid lightgrey;
    resize: none;
    font-size: 16px;
    min-height: 40px;
    overflow: hidden;
    padding: 8px;
    border-radius: 4px;
  }

  & .buttons-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    & button {
      padding: 8px 12px;
      border: 0;
      border-radius: 8px;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
      &.cancel-button {
        background-color: #e0e1e2;
        color: rgba(0, 0, 0, 0.6);
      }
      &.report-button {
        background-color: var(--ac-color-primary);
        color: white;
      }
      &.disabled {
        opacity: 0.7;
        cursor: not-allowed !important;
      }
    }
  }
}

.bottom-panel__content:has(.report-reasons) {
  padding: 16px !important;
}
