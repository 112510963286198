.details {
  &__container {
    position: relative;
    display: flex;
    align-items: center;

    .favorite.exhibitor .icon {
      position: absolute;
      top: -10px;
      right: -14px;
      z-index: 100;
    }

    .logo {
      margin-right: 25px;
      padding: 8px;
      padding: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.hasBanner {
      display: block;

      .banner {
        width: 100%;
        padding-top: 30%;
        background-position: center;
        background-size: cover;
      }

      .logo {
        position: absolute;
        left: 30px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        background: white;
        padding: 4px;
        border-radius: 4px;
        bottom: -40px;
      }
    }
  }
}

@media (min-width: 768px) {
  .page.page--exhibitor {
    .go-back {
      display: inline-block;
      cursor: pointer;
      margin-bottom: 8px;
    }
  }
}

@media (max-width: 767px) {
  .page.page--exhibitor {

    .ui.container {
      padding-bottom: 6em;
    }

    .go-back {
      margin-top: 20px;
      font-size: 1.2em;
      margin-bottom: 8px;
    }

    .details {
      &__container {
        display: block !important;

        .logo {
          margin-bottom: 10px;
        }
      }
    }
  }
}
