.GamificationCodeBlock {
  .ui.form {
    .ui.labeled.input {
      > .label {
        padding-left: 2.5em;
        padding-right: 2.5em;
      }
    }
  }
}
