.FaqBlock {
  .Collapsible {
    border-top: 1px solid #ddd;
  }

  &__question {
    display: flex;
    cursor: pointer;
    display: flex;
    align-items: center;
    &:focus {
      outline: solid;
    }

    .title {
      padding: 1em;
      flex: 1;
      font-weight: bold;
      text-align: left;
      background: initial;
      border: initial;
      cursor: pointer;
    }
  }

  &__answer {
    padding: 1em;
    padding-top: 0em;

    pre {
      white-space: normal;
    }
  }

  &__noResult {
      display: none!important;
      &:nth-child(2) {
        display: flex!important;
      }
  }


}