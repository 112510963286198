.social-wall-slider {
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media (max-width: 768px) {
    margin: auto;
    max-width: 500px;
  }
  h3 {
    color: #4a4a73;
    margin-bottom: 0px;
  }

  .slider {
    &-list {
      height: 100% !important;
    }
    &-slide {
      height: 100% !important;
    }
    &-control-centerleft {
      cursor: pointer;
      i {
        margin: 0px;
      }
      left: -1.5rem !important;
      @media (max-width: 480px) {
        left: -1rem !important;
      }
    }
    &-control-centerright {
      cursor: pointer;
      i {
        margin: 0px;
      }
      right: -1.5rem !important;
      @media (max-width: 480px) {
        right: -1rem !important;
      }
    }
  }

  &-header {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    &__button {
      background-color: #4a4a73 !important;
      color: white !important;
    }
  }
  .slide-card {
    display: flex;
    height: 100%;
    overflow: hidden;
    gap: 0.5rem;
    padding: 12px;
    background-color: #ffffff;
    border-radius: 12px;
    border: solid 1px #e0e0e0;

    &.list-view {
      align-items: center;

      img.slide-card__image {
        height: 100px;
      }

      .slide-card__info {
        flex-direction: column;
        flex-grow: 1;
      }
    }
    &.grid-view {
      flex-direction: column;
    }

    img.slide-card__image {
      border-radius: 0.5rem;
      width: auto;
      object-fit: cover;
      flex-grow: 1;
      max-width: 100% !important;
      height: 100%;
      overflow: hidden;
    }
    &__info {
      display: flex;
      width: 100%;
      gap: 0.5rem;

      &__user {
        img.avatar {
          max-width: 100% !important;
          width: 40px;
          height: 40px;
          object-fit: contain;
        }
        display: flex;
        align-items: center;
        text-align: left;
        flex: 1;
      }

      &__description {
        text-align: left;
      }

      &__reaction {
        color: #4a4a73;
        gap: 0.5rem;
        align-items: center;
        justify-content: flex-end;
        display: flex;
        flex: 1;

        span {
          display: flex;
          gap: 0.25rem;
        }
      }
    }
  }
}
