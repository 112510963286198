$card-max-width: 750px;

.publication-card {
  width: 100%;
  background-color: #ffffff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
  max-width: $card-max-width;
  box-shadow: var(--ac-shadow-tertiary);
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;

  .material-symbols-outlined {
    max-width: 1.5em;
    overflow: hidden;
  }

  &__wrapper {
    padding-bottom: 12px;
  }

  & .publication-header {
    margin-bottom: 16px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 16px;
    gap: 10px;
    padding: 0 10px;

    & .image-wrapper {
      position: relative;
      border-radius: 12px;
      overflow: hidden;
      background: rgba(0, 0, 0, 0.05);

      &__vertical {
        height: 300px;
        max-width: 100%;
      }
      &__horizontal {
        width: 100%;
        max-width: 500px;
      }
      & img {
        object-fit: contain;
        max-width: 100% !important;
        width: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }
    }
  }
  &__footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
    padding: 0 10px;
    &--end {
      justify-content: flex-end !important;
    }
  }
  &__comments {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 8px;
    &:hover {
      cursor: pointer;
    }
  }
}

@media screen and (min-width: $card-max-width) {
  .publication-card {
    max-width: 60% !important;
  }
}
