.file-thumbnail-container {
  position: relative;
  margin-top: 5px;
  max-width: 100%;

  & button {
    position: absolute;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 100%;
    border: none;
    top: 5px;
    right: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  & .video-thumbnail,
  .image-thumbnail {
    display: flex;
    justify-content: center;
    align-items: center;
    & img {
      border-radius: 12px;
    }
    &__vertical {
      & img,
      video {
        max-height: 400px;
        max-width: 100%;
        min-width: 100px;
        min-height: 100px;
        width: auto;
      }
    }
    &__horizontal {
      & img,
      video {
        width: 100%;
        max-width: 500px;
        height: auto;
      }
    }
  }

  .video-thumbnail {
    cursor: pointer;
  }

  & .iframe-wrapper {
    overflow: hidden;
    border-radius: 12px;
  }
}
