
/* Badge Toast */
.Toastify__toast-container.badge-notification {
    .Toastify__toast {
      padding: 0;
      border-radius: 12px;
      min-height: 16em;
    }
  
    .achievement-notify {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 1em;
  
      .text {
        font-weight: bold;
        font-size: 1.2em;
        line-height: 1.2;
        text-align: center;
        color: #ffffff;
        padding: 0 1.25em;
      }
  
      .unlock-image {
        height: 9em;
        border: solid #ffffff 0.01em;
        border-radius: 50%;
        margin-top: 1.2em;
        margin-bottom: 0.8em;
      }
    }
  
    .Toastify__close-button {
      position: absolute;
      top: 1em;
      right: 1em;
      color: #ffffff;
      opacity: 0.5;
  
      & > svg {
        height: 2em;
        width: 2em;
        font-weight: 100;
      }
    }
  
    .Toastify__progress-bar {
      background: #ffffff;
    }
  }
  