$main-background: #f6f6f6;
$vertical-padding: 16px;
$horizontal-padding: 12px;

.social-wall-wrapper {
  display: flex;
  overflow: hidden;
  flex: 1;
  position: relative;
  background: $main-background;
}

.social-wall {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  & > .ui.secondary.pointing.menu {
    position: sticky;
    top: 0;
    background: white;
    margin-bottom: 0 !important;
  }
  & > .ui.secondary.pointing.menu .item {
    flex: 1;
    justify-content: center;
    margin: -2px 10px;
  }
  &__content {
    height: 100%;
    padding: $vertical-padding $horizontal-padding;
    position: relative;
    // overflow-y: scroll;
    &--empty {
      height: 75%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}

.social-wall-button {
  position: fixed;
  bottom: calc(20px + env(safe-area-inset-bottom));
  right: 10px;
  padding: 10px;
  box-shadow: none;
  border: 0;
  border-radius: 50px;
  overflow: hidden;
  z-index: var(--social-wall-button-index);
  &__high {
    bottom: calc(60px + env(safe-area-inset-bottom));
  }
}

.publication-list,
.social-wall-media-page {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;

  .virtualized-list {
    padding: 12px;
    padding-bottom: 70px;
  }
}

@media screen and (max-width: 767px) {
  .social-wall__content,
  .ReactVirtualized__List,
  .ReactVirtualized__Masonry,
  .ReactVirtualized__Masonry__innerScrollContainer {
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
