$text-color: #8e8c96;

.create-publication-modal {
  & .content {
    padding: 0 !important;
  }
}
.created-publication {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  width: 100%;
  height: 100%;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    overflow-y: auto;
    min-height: 40vh;
  }

  & textarea {
    border: 0;
    resize: none;
    font-size: 16px;
    min-height: 40px;
    overflow: hidden;
    width: 100% !important;

    &.textarea-full {
      flex: 1;
    }

    ::placeholder {
      color: $text-color;
      opacity: 1;
    }

    &:focus {
      outline: none;
    }

    &:disabled {
      background-color: inherit;
    }
  }
}

.textarea-informations {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 5px;
  align-self: flex-end !important;
  & .character-count {
    color: $text-color;
  }
}

.keyboard-toolbar {
  width: 100%;
}

.upload-toolbar--desktop {
  width: 80% !important;
  margin: auto;
  margin-bottom: 20px;
}

#button {
  transition: bottom 100ms;
}
